<template>
  <main class="container mt-5">
    <h1>ข้อกำหนดและเงื่อนไขการใช้งาน</h1>
    <p>
      ข้อกำหนดและเงื่อนไขในการใช้งานฉบับนี้ ("ข้อกำหนดและเงื่อนไขฯ" หรือ
      "สัญญา") เป็นข้อตกลงระหว่าง PDPA Pro ("บริษัท เอ็นเดต้าธอธ จำกัด" หรือ
      "เรา") และท่าน ("ผู้ใช้" หรือ "ท่าน")
      สัญญาฉบับนี้ได้กำหนดข้อกำหนดและเงื่อนไขทั่วไปสำหรับการใช้งาน PDPA Pro
      ผลิตภัณฑ์หรือบริการ และการเข้าร่วมกิจกรรมที่เกี่ยวข้องกับ PDPA Pro ใด ๆ
      ก็ตามของท่าน (เรียกรวมกันว่า "เว็บไซต์" หรือ "บริการ")
    </p>
    <section id="account-and-membership">
      <h2>บัญชีผู้ใช้และสมาชิก</h2>
      <p>
        หากท่านได้ทำการสร้างบัญชีผู้ใช้บนเว็บไซต์
        ท่านมีหน้าที่ในการรักษาความปลอดภัยของบัญชีของท่านและท่านต้องรับผิดชอบต่อกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้การใช้งานบัญชีหรือการดำเนินการอื่นใดที่เกี่ยวข้องกับบัญชีนั้น
        เราอาจมีการสังเกตการณ์และตรวจสอบบัญชีใหม่ก่อนที่ท่านจะลงชื่อเข้าใช้และใช้บริการของเรา
        การให้ข้อมูลติดต่อที่เป็นเท็จไม่ว่าประเภทใดก็ตามอาจส่งผลให้บัญชีของท่านถูกยุติการใช้งาน
        ท่านต้องแจ้งให้เราทราบทันทีเมื่อบัญชีของท่านถูกนำไปใช้โดยไม่ได้รับอนุญาตหรือเมื่อมีการละเมิดความปลอดภัยอื่น
        ๆ ทางเราจะไม่ขอรับผิดชอบต่อความเสียหายใด ๆ
        ที่เกิดขึ้นจากการกระทำหรือการละเว้นการกระทำของท่าน เราอาจทำการระงับ
        ปิดการใช้งาน หรือลบบัญชีผู้ใช้ของท่าน (หรืออย่างใดอย่างหนึ่ง)
        หากเราพิจารณาว่าท่านได้ละเมิดข้อกำหนดใด ๆ
        ของข้อตกลงนี้หรือการกระทำของท่านมีแนวโน้มที่จะทำลายชื่อเสียงและค่าความนิยมของเรา
        หากเราลบบัญชีของท่านด้วยเหตุผลข้างต้น
        ท่านจะไม่สามารถลงทะเบียนใหม่เพื่อใช้บริการของเราได้
        โดยเราอาจทำการปิดกั้นที่อยู่อีเมลและที่อยู่อินเทอร์เน็ตโปรโตคอลของท่านเพื่อป้องกันการลงทะเบียนอีกครั้ง
      </p>
    </section>
    <section id="user-content">
      <h2>เนื้อหาของผู้ใช้งาน</h2>
      <p>
        เราไม่ได้เป็นเจ้าของข้อมูลหรือเนื้อหาใด ๆ (“เนื้อหา”)
        ที่ท่านได้ให้ไว้บนเว็บไซต์เพื่อการใช้งานใช้บริการ
        ท่านจะต้องรับผิดชอบต่อความถูกต้อง คุณภาพ ความสมบูรณ์
        ความถูกต้องตามกฎหมาย ความน่าเชื่อถือ
        ความเหมาะสมและความเป็นเจ้าของทรัพย์สินทางปัญญาหรือสิทธิในการใช้เนื้อหาที่นำส่งทั้งหมดแต่เพียงผู้เดียว
        เราอาจสังเกตการณ์และตรวจสอบเนื้อหาบนเว็บไซต์ที่ท่านนำส่งหรือสร้างขึ้นจากการบริการโดยตัวท่าน
        การใช้งานเว็บไซต์ โดยท่าน ไม่ถือเป็นการอนุญาตเราในการใช้ ทำซ้ำ ดัดแปลง
        แก้ไข เผยแพร่
        หรือแจกจ่ายเนื้อหาที่ท่านสร้างขึ้นหรือจัดเก็บไว้ในบัญชีผู้ใช้ของท่านเพื่อการค้า
        การตลาดหรือวัตถุประสงค์อื่นใดที่มีคล้ายกันเว้นแต่ท่านจะให้การอนุญาตแก่เราเป็นการเฉพาะ
        อย่างไรก็ตาม ท่านให้สิทธิแก่เราในการเข้าถึง คัดลอก แจกจ่าย จัดเก็บ ส่ง
        จัดรูปแบบใหม่และแสดงเนื้อหาของบัญชีผู้ใช้ของท่านตามที่กำหนดไว้สำหรับวัตถุประสงค์ในการให้บริการแก่ท่านเท่านั้น
        เราขอสงวนสิทธิในการปฏิเสธหรือลบเนื้อหาใด ๆ ที่ละเมิดนโยบายของเรา
        ไม่เหมาะสม
        หรือเป็นอันตรายในทางใดทางหนึ่งต่อข้อกำหนดและเงื่อนไขตามดุลยพินิจของเราโดยไม่เป็นการจำกัดการรับรองและการรับประกันที่เราได้กำหนดไว้
      </p>
    </section>
    <section id="billing-and-payments">
      <h2>การเรียกเก็บเงินและชำระเงิน</h2>
      <p>
        ท่านจะต้องชำระค่าธรรมเนียมหรือเงินเพิ่มของบัญชีการใช้งานของท่านตามค่าธรรมเนียม
        เงินเพิ่ม และข้อกำหนดเรื่องการเรียกเก็บเงินเมื่อถึงครบกำหนดชำระ
        ในกรณีที่มีการนำเสนอบริการแบบทดลองใช้โดยไม่เสียค่าใช้จ่าย
        ท่านอาจต้องชำระเงินหลังจากที่ช่วงทดลองใช้โดยไม่เสียค่าใช้จ่ายได้สิ้นสุดลง
        ไม่ใช่เมื่อท่านใส่รายละเอียดในการเรียกเก็บเงินของท่าน
        (ซึ่งอาจจำเป็นต้องใส่ก่อนที่จะเริ่มช่วงทดลองโดยไม่เสียค่าใช้จ่าย)
        หากการต่ออายุการใช้งานโดยอัติโนมัติมีการเปิดใช้งานสำหรับการบริการที่ท่านได้เลือกใช้
        ท่านจะถูกเรียกเก็บเงินโดยอัตโนมัติตามเงื่อนไขที่ท่านได้เลือกไว้
        หากเรามีความเห็นว่าการทำธุรกรรมของท่านถือเป็นการทำธุรกรรมที่มีความเสี่ยงสูง
        เราจะขอให้ท่านส่งสำเนาบัตรที่มีรูปถ่ายของเจ้าของบัตรซึ่งออกโดยหน่วยงานราชการหรือสำเนาใบแจ้งยอดบัญชีธนาคารล่าสุดสำหรับบัตรเครดิตหรือบัตรเดบิตที่ใช้สำหรับการซื้อในครั้งนั้น
        ๆ ไป
      </p>
      <p>
        เราขอสงวนสิทธิในการเปลี่ยนแปลงผลิตภัณฑ์ บริการราคาผลิตภัณฑ์หรือบริการ
        และกิจกรรมที่เกี่ยวข้องกับ PDPA Pro ได้ตลอดเวลา
        และเราขอสงวนสิทธิในการปฏิเสธคำสั่งซื้อใด ๆ ที่ท่านสั่งซื้อกับเรา
        โดยเราอาจจำกัดคำสั่งซื้อตามดุลยพินิจของเรา
      </p>
      <p>
        ข้อจำกัดเหล่านี้อาจรวมไปถึงคำสั่งซื้อที่ดำเนินการซื้อโดยหรือภายใต้บัญชีลูกค้าเดียวกัน
        บัตรเครดิตเดียวกัน และ/ หรือคำสั่งซื้อที่ใช้การเรียกชำระค่าธรรมเนียม
        และ/หรือที่อยู่ที่ใช้ในการจัดส่ง
        ในกรณีที่เราทำการเปลี่ยนแปลงหรือยกเลิกคำสั่งซื้อ
        เราจะพยายามแจ้งให้ท่านทราบโดยติดต่อไปทางอีเมลและ/หรือที่อยู่สำหรับการเรียกชำระค่าธรรมเนียม
        หรือหมายเลขโทรศัพท์ที่ท่านได้ให้ไว้ในขณะที่ทำการสั่งซื้อ
      </p>
      <p>
        PDPA Pro นำเสนอผลิตภัณฑ์และบริการเพื่อการจำหน่าย
        เมื่อเราได้รับคำสั่งซื้อและการชำระค่าธรรมเนียมของท่าน
        และการชำระค่าธรรมเนียมนั้นได้ผ่านการตรวจสอบการฉ้อโกงเรียบร้อยแล้ว
        เราจะให้สิทธิในการเข้าถึงผลิตภัณฑ์หรือบริการที่ซื้อแก่ท่านโดยเร็วที่สุด
        ผลิตภัณฑ์ที่ไม่เสียค่าใช้จ่ายจะมีการจำกัดระบบการใช้งาน
        ค่าธรรมเนียมทั้งหมดไม่สามารถขอคืนได้
      </p>
    </section>
    <section id="refund-policy">
      <h2>นโยบายการคืนเงิน</h2>
      <p>
        เนื่องจากเว็บไซต์ของเรานำเสนอผลิตภัณฑ์ที่จับต้องไม่ได้และไม่สามารถเพิกถอนหลังจากที่เราได้ให้สิทธิการเข้าถึงแก่ท่านไปแล้ว
        เราจึงขอสงวนสิทธิในการคืนเงินให้กับท่านหลังจากที่ได้ทำการชำระเงินสำหรับผลิตภัณฑ์
        ซึ่งท่านรับทราบก่อนที่จะซื้อผลิตภัณฑ์และบริการใด ๆ บนเว็บไซต์แล้ว
        โปรดอ่านรายละเอียดการบริการอย่างละเอียดก่อนตัดสินใจทำการสั่งซื้อ
      </p>
    </section>
    <section id="accuracy-of-information">
      <h2>ความถูกต้องและสมบูรณ์ของข้อมูล</h2>
      <p>
        ในบางโอกาส ข้อมูลบนเว็บไซต์ อาจมีข้อผิดพลาด เช่น มีการสะกดผิด
        ความไม่ถูกต้องของข้อมูล
        หรือการละเว้นในบางจุดที่อาจมีความเกี่ยวข้องกับรายการส่งเสริมการขายและข้อเสนอ
        เราขอสงวนสิทธิ์ในการแก้ไขข้อผิดพลาด ความไม่ถูกต้องหรือการละเว้นใด ๆ
        และในการการเปลี่ยนแปลง
        ทำให้ข้อมูลเป็นปัจจุบันหรือยกเลิกคำสั่งซื้อหากข้อมูลใด ๆ
        บนเว็บไซต์หรือบริการที่เกี่ยวข้องมีความไม่ถูกต้องเมื่อใดก็ได้โดยไม่จำเป็นต้องแจ้งให้ท่านทราบล่วงหน้า
        (รวมถึงหลังจากที่ท่านส่งคำสั่งซื้อแล้ว)
        เราไม่มีหน้าที่ในการทำให้ข้อมูลเป็นปัจจุบัน แก้ไข
        หรือชี้แจงในข้อมูลบนเว็บไซต์ ซึ่งรวมไปถึงแต่ไม่จำกัดไว้เพียง ข้อมูลราคา
        เว้นแต่ว่ากฎหมายได้มีการกำหนดให้ต้องกระทำ
        จะไม่มีการกำหนดวันที่หรือวันที่มีเปลี่ยนแปลงบนเว็บไซต์ หรือบริการอื่น ๆ
        ที่เกี่ยวข้องเมื่อมีการแก้ไขหรือทำให้เป็นปัจจุบัน
      </p>
    </section>
    <section id="third-party-services">
      <h2>บริการของบุคคลที่สาม</h2>
      <p>
        หากท่านได้เลือกที่จะเปิดใช้ เข้าถึง หรือใช้บริการของบุคคลที่สาม
        ขอให้ท่านคำนึงเอาไว้ว่าการเข้าใช้หรือใช้งานบริการดังกล่าวได้ถูกกำหนดโดยเงื่อนไขและข้อกำหนดของการให้บริการอื่นนั้นแต่เพียงผู้เดียว
        และทางเราจะไม่ขอรับรอง มีหน้าที่หรือรับผิดชอบ
        และไม่ขอให้คำรับรองสำหรับบริการอื่นดังกล่าว
        ซึ่งรวมไปถึงแต่ไม่จำกัดไว้เพียงเนื้อหาหรือวิธีที่บุคคลเหล่านั้นจัดการกับข้อมูล
        (รวมถึงข้อมูลส่วนบุคคลของท่าน)
        หรือการติดต่อกันระหว่างท่านและผู้ให้บริการอื่นนั้น
        ท่านขอสละสิทธิ์โดยไม่เปลี่ยนแปลงว่าจะไม่เรียกร้องจากบริษัท เอ็นเดต้าธอธ
        จำกัด ในเรื่องที่เกี่ยวข้องกับการบริการอื่นดังกล่าว บริษัท เอ็นเดต้าธอธ
        จำกัด
        จะไม่รับผิดชอบต่อความเสียหายหรือความสูญเสียที่ได้เกิดขึ้นหรือถูกกล่าวหาว่าได้เกิดขึ้นโดยหรือเกี่ยวข้องกับการที่ท่านเลือกใช้
        เข้าถึง หรือใช้บริการอื่นดังกล่าว
        หรือจากการที่ท่านได้ไว้วางใจในการปฏิบัติต่อความเป็นส่วนตัว
        ความปลอดภัยของการประมวลผลข้อมูล หรือนโยบายอื่นใดของบริการดังกล่าว
        ท่านอาจจำเป็นจะต้องทำการลงทะเบียนหรือลงชื่อเข้าใช้ในบริการอื่นของเว็บไซต์นั้น
        ๆ ในการเปิดใช้งานบริการอื่น ๆ นั้น
        ถือว่าท่านได้ให้ความยินยอมโดยชัดแจ้งแก่บริษัท เอ็นเดต้าธอธ จำกัด
        ในการเปิดเผยข้อมูลของท่านได้เท่านที่จำเป็นเพื่อการทำงานของบริการหรือเพื่อการเปิดใช้บริการอื่นนั้น
        ๆ
      </p>
    </section>
    <section id="uptime-guarantee">
      <h2>การรับประกันระยะเวลาที่ทำงานอย่างมีประสิทธิภาพ</h2>
      <p>
        เราเสนอบริการที่รับประกันระยะเวลาที่ทำงานอย่างมีประสิทธิภาพที่อยู่ในอัตราร้อยละ
        99 จากระยะเวลาที่ทำงานได้ต่อเดือน
        หากเราไม่รักษาไว้ซึ่งการรับประกันระยะเวลาที่ทำงานอย่างมีประสิทธิภาพในเดือนนั้น
        (ตามที่ได้กำหนดไว้โดยเรา)
        ท่านสามารถติดต่อเราและร้องขอเครดิตคืนจากค่าธรรมเนียมบริการของท่านในเดือนดังกล่าวได้
        เครดิตสามารถใช้เพียงสำหรับการซื้อผลิตภัณฑ์หรือบริการเพิ่มเติมจากเราเพียงเท่านั้นและไม่รวมค่าภาษีที่อาจเกิดขึ้น
        การรับประกันระยะเวลาที่ทำงานอย่างมีประสิทธิภาพจะไม่รวมความขัดข้องของการบริการที่เกิดขึ้นโดย
        (1)
        การดูแลรักษาหรือซ่อมแซมตามช่วงระยะเวลาที่เราอาจดำเนินการเป็นครั้งคราว
        (2) ความขัดข้องที่เกิดขึ้นโดยท่านหรือจากการกระทำของท่าน (3)
        ไฟฟ้าขัดข้องที่ไม่ส่งผลกระทบต่อระบบการทำงานหลักของบริการ (4)
        ได้เกิดขึ้นโดยเหนือการควบคุมของเราหรือมีเหตุผลว่าเราไม่สามารถคาดหมายได้
        (5) ไฟฟ้าขัดข้องที่เกี่ยวข้องกับสภาพแวดล้อมในการเขียนโปรแกรมบางอย่าง
      </p>
    </section>
    <section id="backups">
      <h2>การสำรองข้อมูล</h2>
      <p>
        เราทำการสำรองข้อมูลเว็บไซต์และเนื้อหาไว้เป็นประจำและเราจะกระทำอย่างสุดความสามารถเพื่อให้มั่นใจได้ว่าข้อมูลที่ทำการสำรองนั้นมีความครบถ้วนและถูกต้องที่สุด
        ในกรณีที่มีความขัดข้องในฮาร์ดแวร์หรือเกิดความสูญหายในข้อมูล
        เราจะทำการกู้คืนข้อมูลที่สำรองไว้โดยอัติโนมัติเพื่อทำให้ผลกระทบหรือช่วงเวลาที่ไม่สามารถทำงานได้
        (downtime) มีอัตราที่ลดลง
      </p>
    </section>
    <section id="links-to-other-websites">
      <h2>เชื่อมต่อไปยังเว็บไซต์อื่น</h2>
      <p>
        แม้ว่าเว็บไซต์นี้อาจมีการเชื่อมต่อไปยังเว็บไซต์อื่น
        เราไม่ได้อนุมัติโดยเป็นนัยยะ ร่วมกัน สนับสนุน ช่วยเหลือ
        หรือเป็นเครือเดียวกันกับเว็บไซต์อื่นที่ได้มีการเชื่อมต่อไม่ว่าจะทางตรงหรืออ้อม
        เว้นเสียแต่ได้กำหนดไว้เป็นการเฉพาะไว้ในที่นี้
        เราไม่มีหน้าที่ในการตรวจสอบหรือประเมิน
        และเราไม่ขอรับรองในข้อเสนอของธุรกิจหรือบุคคลใด ๆ
        หรือเนื้อหาในเว็บไซต์นั้น เราไม่รับผิดชอบหรือรับผิดใด ๆ ต่อการกระทำ
        ผลิตภัณฑ์ บริการและเนื้อหาของบุคคลอื่น ๆ ดังนั้น
        ท่านควรตรวจสอบการชี้แจงทางกฎหมายและเงื่อนไขในการใช้งานของเว็บไซต์ใด ๆ
        ที่ท่านได้เชื่อมต่อผ่านเว็บไซต์นี้
        ท่านเป็นผู้รับความเสี่ยงที่อาจเกิดขึ้นทั้งหมดในการเชื่อมต่อไปยังเว็บไซต์อื่น
        ๆ
      </p>
    </section>
    <section id="referral-program">
      <h2>กิจกรรมการแนะนำผู้ซื้อรายใหม่ (Referral Program)</h2>
      <p>
        ทางเรามีการจัดกิจกรรมการแนะนำผู้ซื้อรายใหม่ (Referral Program)
        ขึ้นเพื่อให้ผู้ที่สนใจในกิจกรรมการแนะนำของเราได้สมัครเข้าร่วมและนำรหัสผู้แนะนำที่ได้รับจากกิจกรรมไปให้ลูกค้ารายใหม่ที่ยังไม่เคยมีการสั่งซื้อสินค้าหรือบริการกับเรามาก่อนทำการกรอกรหัสผู้แนะนำในขั้นตอนการชำระเงิน
        โดยผู้เข้าร่วมกิจกรรมจะได้รับค่าตอบแทนจากคำสั่งซื้อที่ได้มีการกรอกรหัสผู้แนะนำและเสร็จสิ้นในขั้นตอนชำระเงินและลูกค้ารายใหม่ที่ใช้รหัสผู้แนะนำจะได้รับส่วนลดเมื่อซื้อสินค้าหรือบริการกับเราเป็นครั้งแรก
      </p>
      <p>
        ท่านสามารถศึกษาเพิ่มเติมในรายละเอียดเกี่ยวกับข้อกำหนดและเงื่อนไขของกิจกรรมการแนะนำดังกล่าวได้ที่<a
          href="/referral/terms"
          >กำหนดและเงื่อนไขกิจกรรมการแนะนำผู้ซื้อรายใหม่ (Referral Program)</a
        >
      </p>
    </section>
    <section id="prohibited-uses">
      <h2>ข้อห้ามในการใช้งาน</h2>
      <p>
        นอกจากข้อตกลงอื่นที่ได้กำหนดไว้ภายใต้สัญญา ห้ามมิให้ท่านใช้งานเว็บไซต์
        หรือเนื้อหาในลักษณะดังนี้ (ก) เพื่อวัตถุประสงค์ที่มิชอบด้วยกฎหมาย (ข)
        ชักชวนหรือสนับสนุนผู้อื่นให้ดำเนินการหรือมีส่วนร่วมในการกระทำที่ผิดกฎหมาย
        (ค) ละเมิดกฎระเบียบ ข้อบังคับ กฎหมายหรือข้อบัญญัติท้องถิ่นระหว่างประเทศ
        รัฐบาลกลาง จังหวัดหรือรัฐ (ง) กระทำการละเมิดสิทธิในทรัพย์สินทางปัญญาใด ๆ
        ของเราหรือสิทธิในทรัพย์สินทางปัญญาของผู้อื่น (จ) คุกคาม ล่วงละเมิด
        ดูหมิ่น ทำร้าย ทำให้เสียชื่อเสียง ใส่ร้าย ดูหมิ่น
        ข่มขู่หรือเลือกปฏิบัติตามเพศ รสนิยมทางเพศศาสนา ชาติพันธุ์ เชื้อชาติ อายุ
        ชาติกำเนิดหรือความทุพพลภาพ (ฉ) นำส่งข้อมูลเท็จหรือบิดเบือนข้อมูล (ช)
        เพื่ออัปโหลดหรือส่งไวรัสหรือรหัสที่เป็นอันตรายประเภทอื่นที่จะหรืออาจใช้ในลักษณะใด
        ๆ
        ที่จะส่งผลกระทบต่อการทำงานหรือการทำงานของบริการหรือเว็บไซต์ที่เกี่ยวข้อง
        เว็บไซต์อื่น ๆ หรืออินเทอร์เน็ต (ซ)
        เก็บรวบรวมหรือติดตามข้อมูลส่วนบุคคลของผู้อื่น (ฌ)
        การส่งข้อความที่ผู้รับไม่ได้ร้องขอ (spam)
        การหลอกลวงโดยใช้อีเมลหรือหน้าเว็บไซต์ปลอม (phish)
        การเปลี่ยนแปลงเส้นทางการเข้าชมเว็บไซต์ไปยังเว็บไซต์ปลอมเพื่อโจรกรรมข้อมูลผู้ใช้
        (pharm) แอบอ้างเป็นผู้มีสิทธิเข้าถึงข้อมูล (pretext)
        การใช้โปรแกรมเก็บรวบรวมข้อมูลบนเว็บไซต์ (spider)
        การเก็บรวบรวมข้อมูลเว็บไซต์ (crawl) หรือการดึงข้อมูลจากเว็บไซต์อื่น ๆ
        เพื่อนำมาใช้งาน (scrape) (ญ) เพื่อวัตถุประสงค์ในทางอนาจารหรือผิดศีลธรรม
        หรือ (ฎ)
        เพื่อแทรกแซงหรือหลีกเลี่ยงคุณลักษณะทางด้านความปลอดภัยของบริการหรือเว็บไซต์ที่เกี่ยวข้อง
        เว็บไซต์อื่น ๆ หรืออินเทอร์เน็ต
      </p>
      <p>
        เราขอสงวนสิทธิ์ในการยกเลิกการใช้บริการหรือใช้เว็บไซต์ใด ๆ
        ของท่านจากการฝ่าฝืนการใช้งานที่ต้องห้าม
      </p>
    </section>
    <section id="intellectual-property-rights">
      <h2>สิทธิในทรัพย์สินทางปัญญา</h2>
      <p>
        สัญญานี้จะไม่เป็นการโอนสิทธิในทรัพย์สินทางปัญญาใด ๆ ที่เป็นของ บริษัท
        เอ็นเดต้าธอธ จำกัด หรือบุคคลที่สามไปให้แก่ท่าน โดยสิทธิ กรรมสิทธิ์
        และผลประโยชน์ในทั้งหมดของทรัพย์สินดังกล่าวซึ่งไม่จำกัดไว้เพียงลิขสิทธิ์
        สิทธิบัตร
        สิทธิในความลับทางการค้าและสิทธิในทรัพย์สินทางปัญญาอื่นใดจะยังคงอยู่
        (ในระหว่างคู่สัญญา) กับ บริษัท เอ็นเดต้าธอธ จำกัด แต่เพียงผู้เดียว
        เครื่องหมายทางการค้า เครื่องหมายบริการ กราฟิก
        และโลโก้ทั้งหมดที่ถูกใช้เกี่ยวกับเว็บไซต์หรือบริการของเราหรือเว็บไซต์ที่เป็นกิจกรรมเกี่ยวข้องกับ
        PDPA Pro
        เป็นเครื่องหมายทางการค้าหรือเครื่องหมายทางการค้าที่ได้จดทะเบียนของ
        บริษัท เอ็นเดต้าธอธ จำกัด หรือผู้อนุญาตให้สิทธิในการใช้แก่บริษัท
        เอ็นเดต้าธอธ จำกัด เครื่องหมายการค้า เครื่องหมายการบริการ กราฟิก
        และโลโก้
        ที่ใช้ร่วมกับเว็บไซต์หรือบริการของเราอาจเป็นเครื่องหมายการค้าของบุคคลที่สามอื่น
        การใช้งานเว็บไซต์
        และบริการของท่านจะไม่เป็นการให้สิทธิหรืออนุญาตให้ท่านทำซ้ำหรือใช้เครื่องหมายการค้าใดของบริษัท
        เอ็นเดต้าธอธ จำกัด หรือของบุคคลที่สาม
      </p>
    </section>
    <section id="disclaimer-of-warranty">
      <h2>การสงวนสิทธิในการรับประกัน</h2>
      <p>
        ท่านยอมรับว่าการใช้งานเว็บไซต์ บริการ
        หรือการเข้าร่วมในกิจกรรมที่เกี่ยวข้องกับบริการของ PDPA Pro
        ที่ทางเราได้จัดขึ้น เป็นการที่ท่านยอมรับความเสี่ยงเองแต่เพียงผู้เดียว
        ท่านยอมรับว่าบริการดังกล่าวจัดเตรียมมีให้ในรูปแบบ "ตามที่เป็นอยู่" และ
        "ตามที่มีอยู่" เราขอปฏิเสธการรับประกันใด ๆ โดยชัดแจ้ง
        ไม่ว่าโดยชัดแจ้งหรือโดยนัย ซึ่งรวมถึงแต่ไม่จำกัดเพียง
        การรับประกันโดยนัยเกี่ยวกับความสามารถทางการค้า
        ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ และการไม่เกิดเหตุละเมิดการใช้งาน
        เราไม่รับประกันว่าบริการจะตรงตามความต้องการของท่านหรือบริการจะไม่ขัดข้อง
        ใช้ได้ตลอดเวลา ปลอดภัย หรือปราศจากข้อผิดพลาด และเราไม่รับประกันใด ๆ
        เกี่ยวกับผลลัพธ์ที่อาจได้รับจากการใช้บริการและการเข้าร่วมกิจกรรมของท่านหรือความถูกต้องหรือความน่าเชื่อถือของข้อมูลใด
        ๆ ที่ได้รับผ่านบริการหรือข้อบกพร่องที่เกิดขึ้นในบริการจะได้รับการแก้ไข
        ท่านเข้าใจและยอมรับว่าเนื้อหาและ/หรือข้อมูลใด ๆ
        ที่ได้ดาวน์โหลดหรือได้รับจากการใช้บริการจะได้กระทำขึ้นและอยู่กับดุลยพินิจและความเสี่ยงของท่านเองและท่านจะต้องรับผิดชอบแต่เพียงผู้เดียวสำหรับความเสียหายที่เกิดขึ้นกับระบบคอมพิวเตอร์ของท่านหรือการสูญเสียข้อมูลอันเป็นผลมาจาก
        ดาวน์โหลดสิ่งนั้น และ/หรือข้อมูลดังกล่าว
        เราไม่รับประกันสินค้าหรือบริการใด ๆ
        ที่ซื้อหรือได้รับผ่านบริการหรือธุรกรรมใด ๆ ที่ทำผ่านบริการ
        ไม่มีคำแนะนำหรือข้อมูลไม่ว่าจะด้วยวาจาหรือเป็นลายลักษณ์อักษรที่ท่านได้รับจากเราหรือผ่านบริการจะไม่ถือเป็นการรับประกันใด
        ๆ ที่ไม่ได้ระบุไว้อย่างชัดเจนในที่นี้
      </p>
      <p>
        ท่านยินยอมและรับทราบว่าบริษัท เอ็นเดต้าธอธ จำกัด มิใช่สำนักงานกฎหมาย
        นักกฎหมาย ทนายความ หรือให้บริการคำปรึกษาทางกฎหมาย ดังนั้น
        เราจะไม่รับรองในความถูกต้องทางกฎหมายหรือการบังคับใช้ได้ของเอกสารต่าง ๆ
        สำหรับความต้องการที่เกี่ยวข้องกับข้อกฎหมายของท่าน
      </p>
      <p>
        นอกจากนี้ PDPA Pro ไม่ใช่สำนักงานกฎหมายและไม่ได้ให้คำแนะนำทางด้านกฎหมาย
        บริการและ/หรือข้อมูลทั้งหมด (รวมถึงสัญญา แบบฟอร์มและเอกสารอื่น ๆ )
        ที่มีอยู่บนเว็บไซต์ของเรา [โปรดใส่ลิงก์ไปยังเว็บไซต์ PDPA Pro]
        ได้จัดเตรียมให้โดยไม่มีการรับประกันใด ๆ ทั้งโดยชัดแจ้งหรือโดยเป็นนัยยะ
        รวมถึงผลทางกฎหมายและความสมบูรณ์
        บริการและข้อมูลควรใช้เป็นแนวทางและแก้ไขเพื่อให้ตรงกับความต้องการส่วนบุคคลของท่านเองและกฎหมายในประเทศของท่าน
        การใช้บริการข้อมูลแบบฟอร์มหรือเอกสารของท่านถือเป็นความเสี่ยงของท่านเอง
        บริษัท เอ็นเดต้าธอธ จำกัด และพนักงาน ผู้รับเหมา
        หรือทนายความที่มีส่วนในการให้บริการและข้อมูลขอสงวนสิทธิการรับประกันใด ๆ
        โดยชัดแจ้งและไม่ได้มีการเกิดหรือเข้าร่วมในความสัมพันธ์ของทนายความกับลูกค้าโดยการให้บริการและข้อมูลแก่ท่าน
        อย่างไรก็ตาม ท่านทราบดีว่า บริการให้คำปรึกษานี้
        ท่านจำเป็นต้องพิจารณาจากองค์ประกอบภายในองค์กรของท่านเป็นหลักและควรได้รับคำปรึกษาจากที่ปรึกษากฎหมายภายในบริษัทของท่านเองเพื่อให้ท่านมั่นใจว่าคำปรึกษาที่ได้รับจะสอดคล้องกับการดำเนินธุรกิจขององค์กรท่าน
      </p>
    </section>
    <section id="limitation-of-liability">
      <h2>ข้อจำกัดความรับผิด</h2>
      <p>
        ตามขอบเขตสูงสุดของกฎหมายที่บังคับใช้ได้อนุญาต ไม่ว่าในกรณีใดก็ตาม บริษัท
        เอ็นเดต้าธอธ จำกัด บริษัทในเครือ เจ้าหน้าที่ กรรมการ พนักงาน ตัวแทน
        คู่ค้า หรือผู้ได้รับอนุญาตของบริษัท เอ็นเดต้าธอธ จำกัด
        จะไม่รับผิดชอบต่อบุคคลใด ๆ สำหรับเรื่องต่อไปนี้ (ก) ความเสียหายทางอ้อม
        ความเสียหายโดยไม่ตั้งใจ ความเสียหายพิเศษ ความเสียหายในเชิงลงโทษ
        ความเสียหายในการชดใช้ หรือความเสียหายที่เป็นผลสืบเนื่อง
        (ซึ่งรวมถึงแต่ไม่จำกัดเพียง ความเสียหายจากผลกำไร รายได้ การค้าขาย
        ค่าความนิยม การใช้เนื้อหา ผลกระทบต่อธุรกิจ ธุรกิจหยุดชะงัก
        การสูญเสียเงินเก็บที่คาดการณ์เอาไว้ การสูญเสียโอกาสทางธุรกิจ)
        อย่างไรก็ตาม ความรับผิดชอบที่เกิดขึ้นในทางที่จะเป็นได้
        ซึ่งรวมไปถึงแต่ไม่จำกัดเพียงในทางสัญญา ละเมิด การรับรอง
        การผิดจารีตประเพณี ประมาท หรือการใดที่บริษัท เอ็นเดต้าธอธ จำกัด
        ได้รับคำชี้แจงมาแล้วว่าอาจเกิดขึ้นได้หรือคาดหมายได้ว่าความเสียหายดังกล่าวอาจจะเกิดขึ้นก็ตาม
        ตามขอบเขตสูงสุดของกฎหมายที่บังคับใช้ได้อนุญาต
        จำนวนความเสียหายทั้งหมดที่เกี่ยวข้องกับบริการหรือกิจกรรมที่เกี่ยวข้องซึ่งจะต้องรับผิดโดยบริษัท
        เอ็นเดต้าธอธ จำกัด และบริษัทในเครือ เจ้าหน้าที่ กรรมการ พนักงาน ตัวแทน
        คู่ค้า หรือผู้ได้รับอนุญาตของบริษัท เอ็นเดต้าธอธ จำกัด
        จะถูกจำกัดไว้ตามจำนวนจริงที่ท่านได้ชำระให้แก่บริษัท เอ็นเดต้าธอธ จำกัด
        สำหรับระยะเวลา 1
        เดือนล่วงหน้าก่อนเหตุการณ์ที่ทำให้เกิดความรับผิดชอบดังกล่าว
        ข้อจำกัดและการยกเว้นจะไม่มีผลบังคับใช้หากวิธีการเยียวยานี้ไม่สามารถชดเชยความเสียหาย
        ความสูญเสียหรือผิดพลาด ในวัตถุประสงค์หลักได้
      </p>
    </section>
    <section id="indemnification">
      <h2>การชดใช้ค่าเสียหาย</h2>
      <p>
        ท่านตกลงที่จะชดใช้ค่าเสียหายและปกป้อง บริษัท เอ็นเดต้าธอธ จำกัด และ
        บริษัทในเครือ กรรมการ เจ้าหน้าที่ พนักงาน และตัวแทนจากหรือต่อความรับผิด
        ความสูญเสีย ความเสียหายหรือค่าใช้จ่าย รวมถึงค่าทนายความที่เหมาะสม
        ที่เกี่ยวเนื่องหรือเกิดขึ้นจากข้อกล่าวหา ข้อเรียกร้อง การกระทำ ข้อพิพาท
        หรือการดำเนินการข้อพิพาทหรือข้อเรียกร้องใด ๆ
        ของบุคคลที่สามอันเป็นผลมาจากหรือเกี่ยวกับเนื้อหาของท่าน
        การใช้งานหรือบริการโดยท่าน การเข้าร่วมกิจกรรมที่จัดขึ้นโดยบริษัท
        เอ็นเดต้าธอธ จำกัด หรือการกระทำอันมิชอบโดยเจตนาใด ๆ ของท่าน
      </p>
    </section>
    <section id="severability">
      <h2>การแยกข้อสัญญาส่วนที่เป็นโมฆะออกจากส่วนที่ไม่เป็นโมฆะ</h2>
      <p>
        สิทธิและข้อจำกัดทั้งหมดที่มีอยู่ในข้อตกลงนี้อาจถูกใช้
        จะมีผลบังคับใช้และมีผลผูกพันเฉพาะในขอบเขตที่ข้อจำกัดนั้นไม่ฝ่าฝืนกฎหมายที่เกี่ยวข้องใด
        ๆ
        และมีเจตนาที่จำกัดอยู่ในขอบเขตที่จำเป็นเพื่อที่จะไม่ทำให้สัญญาฉบับนี้ผิดกฎหมาย
        ไม่เป็นผล หรือไม่สามารถบังคับใช้ได้
        หากข้อกำหนดหรือส่วนใดส่วนหนึ่งของข้อกำหนดใด ๆ ของสัญญาฉบับนี้ผิดกฎหมาย
        ไม่เป็นผล หรือไม่สามารถบังคับใช้ได้โดยศาลที่มีอำนาจ
        คู่สัญญาตกลงว่าบทบัญญัติหรือส่วนที่เหลืออยู่จะยังเป็นข้อตกลงที่ยังคงมีผลบังคับใช้ได้ต่อไป
      </p>
    </section>
    <section id="content-and-postings">
      <h2>เนื้อหาและการโพสต์</h2>
      <p>
        ท่านไม่สามารถแก้ไข พิมพ์ หรือทำสำเนาในส่วนใดของเว็บไซต์
        การนำบางส่วนของเว็บไซต์ ไปรวมเข้ากับงานอื่น
        ไม่ว่าจะเป็นในรูปแบบสื่อพิมพ์หรืออิเล็กทรอนิกส์หรือรูปแบบอื่นใดก็ตาม
        หรือนำบางส่วนของเว็บไซต์
        ไปรวมไว้ในเว็บไซต์อื่นในรูปแบบการฝังไว้ในเว็บไซต์ (embedding)
        แบ่งไว้เป็นส่วนในหน้าเว็บไซต์ (framing)
        หรือวิธีการอื่นใดโดยไม่ได้รับอนุญาตที่ชัดแจ้งจากบริษัท เอ็นเดต้าธอธ
        จำกัด จะถือเป็นสิ่งต้องห้ามในการกระทำ
      </p>
      <p>
        ท่านจะต้องไม่ทำการอัปโหลดเนื้อหาที่เป็นการหมิ่นประมาท ฉ้อฉล อนาจาร
        หมิ่นประมาท คุกคาม ล่วงล้ำในสิทธิความเป็นส่วนตัวของผู้อื่น
        หรือทางอื่นใดที่ไม่ชอบด้วยกฎหมาย
        ท่านจะต้องไม่ทำการละเมิดในทรัพย์สินทางปัญญาของบุคคลหรือบริษัทอื่น
        ท่านจะต้องไม่ทำการอัปโหลดไวรัสคอมพิวเตอร์หรือโค้ดอื่นใดที่จัดทำขึ้นเพื่อทำให้เกิดความขัดข้อง
        เสียหาย หรือจำกัดระบบการทำงานของซอฟต์แวร์หรือฮาร์ดแวร์ของคอมพิวเตอร์
        ในการนำส่งหรืออัปโหลดไฟล์บนเว็บไซต์ถือว่าท่านได้ยินยอมให้บริษัท
        เอ็นเดต้าธอธ จำกัด ในการนำออกซึ่งไฟล์ใด ๆ ในเวลาใด ๆ ก็ได้
      </p>
    </section>
    <section id="dispute-resolution">
      <h2>การระงับข้อพิพาท</h2>
      <p>
        การเริ่มทำ ตีความ
        และการปฏิบัติภายใต้สัญญาฉบับนี้และข้อพิพาทที่ได้เกิดขึ้นจากสัญญาฉบับนี้จะต้องตกอยู่ภายใต้วิธีการพิจารณากฎหมายแห่งราชอาณาจักรแห่งไทยโดยไม่ต้องคำนึงถึงความขัดแย้งทางกฎหมายและกฎหมายแห่งราชอาณาจักรไทยตามที่สามารถกระทำได้
        เขตอำนาจศาลและสถานที่เดียวสำหรับการดำเนินการทางกฎหมายที่เกี่ยวข้องกับประเด็นข้อพิพาทในเรื่องของสัญญานี้ให้อยู่ในเขตอำนาจศาลแห่งราชอาณาจักรแห่งประเทศไทยและท่านจะนำข้อพิพาทเข้าสู่เขตอำนาจศาลดังกล่าว
      </p>
    </section>
    <section id="assignment">
      <h2>การโอนสิทธิ</h2>
      <p>
        ท่านจะต้องไม่ทำการโอน ขายต่อ อนุญาตช่วง
        หรือทำการโอนย้ายหรือมอบหมายในสิทธิหรือหน้าที่ใด ๆ
        ของท่านที่ได้กำหนดไว้ไม่ว่าจะทั้งหมดหรือบางส่วนโดยไม่ได้รับความยินยอมจากพวกเรา
        โดยที่ความยินยอมของพวกเรานั้นจะขึ้นอยู่กับดุลพินิจของเราแต่เพียงผู้เดียวโดยและไม่มีเงื่อนไขอื่นใด
        การโอนสิทธิหรือโอนย้ายใด ๆ ดังกล่าวจะต้องเป็นโมฆะและไม่มีผล ทั้งนี้
        เรามีอิสระที่จะโอนสิทธิ์หรือภาระผูกพันใด ๆ
        ไม่ว่าทั้งหมดหรือบางส่วนให้กับบุคคลที่สามซึ่งเป็นส่วนหนึ่งของการขายทรัพย์สินหรือหุ้นทั้งหมดหรือในส่วนสำคัญทั้งหมดหรือเป็นส่วนหนึ่งของการควบรวมกิจการ
      </p>
    </section>
    <section id="changes-and-amendments">
      <h2>การเปลี่ยนแปลงและการแก้ไข</h2>
      <p>
        เราขอสงวนสิทธิในการแก้ไขสัญญาฉบับนี้หรือนโยบายที่เกี่ยวข้องกับเว็บไซต์
        หรือบริการในเวลาใดก็ได้
        โดยจะมีผลทันทีเมื่อได้ทำการเผยแพร่สัญญาฉบับล่าสุดลงบนเว็บไซต์
        โดยเราจะทำการระบุวันที่มีการแก้ไขลงไว้ข้างใต้ของสัญญา
        การเข้าใช้งานเว็บไซต์
        หลังจากที่มีการแก้ไขดังกล่าวจะถือเป็นการที่ท่านได้ยินยอมในการแก้ไขนั้น ๆ
      </p>
    </section>
    <section id="acceptance-of-these-terms">
      <h2>การยอมรับในข้อตกลง</h2>
      <p>
        ท่านยอมรับและรับทราบว่าท่านได้อ่านสัญญาฉบับนี้และยินยอมในข้อตกลงและเงื่อนไขทั้งหมดดังกล่าว
        ในการใช้งานเว็บไซต์
        หรือบริการย่อมถือว่าท่านได้ยินยอมที่จะผูกพันกับสัญญาฉบับนี้
        หากท่านไม่ยินยอมในข้อตกลงของสัญญาฉบับนี้
        ท่านจะไม่มีสิทธิในการใช้งานหรือเข้าถึงเว็บไซต์ หรือบริการดังกล่าว
      </p>
    </section>
    <section id="contacting-us1">
      <h2>ติดต่อเรา</h2>
      <p>
        หากท่านต้องการติดต่อเราเพื่อทำความเข้าใจเพิ่มเกี่ยวกับสัญญาฉบับนี้หรือมีข้อสงสัยอื่นใดที่เกี่ยวข้อง
        ท่านสามารถติดต่อเราได้ผ่านการกรอกแบบฟอร์ม ส่งอีเมลมายัง
        info@ndatathoth.com หรือเขียนจดหมายส่งมายัง บริษัท เอ็นเดต้าธอธ จำกัด
        เลขที่ 1778 อาคารซัมเมอร์ฮับ ชั้น 6 ถนนสุขุมวิท เขตคลองเตย พระโขนง
        กรุงเทพมหานคร 10110 ประเทศไทย
      </p>
    </section>
    <div id="endTermsContent" class="css-f70my9">
      เอกสารฉบับนี้ปรับปรุงล่าสุดเมื่อวันที่ 25 มกราคม พ.ศ. 2565
    </div>
  </main>
</template>
